import React, {useState, useEffect} from 'react';


function ItemDetail( {match} ) {

  useEffect( () => {
    fetchDrink();
    console.log(match);
  }, []);

  const [drink, setDrink] = useState([]);

  const fetchDrink = () => {
    fetch(`https://the-cocktail-db.p.rapidapi.com/lookup.php?i=${match.params.id}`, {
  	"method": "GET",
  	"headers": {
  		"x-rapidapi-host": "the-cocktail-db.p.rapidapi.com",
  		"x-rapidapi-key": "f113642856msh6e73ca1272580f3p12c05cjsnfbb603628da3"
      	}
      })
      .then(res => res.json()) // this returns another promise (res).
      .then(data => {
        console.log(data)
        setDrink((data.drinks[0]))
      })
      .catch(err => {
      	console.log(err);
      });
    }

    function ingredients() {
      console.log("indredient 1 is " + drink.strIngredient11);
      const numOfIngredients=15;
      let list=[];
      //let list="<ul>";
      for(let i=1; i<=numOfIngredients; i++) {
        let thisIng = "strIngredient"+i;
        let thisMeas = "strMeasure"+i;
        if (drink[thisIng]) {
          //list+="<li>"+drink[thisIng]+ ".  QTY: " + drink[thisMeas] + "</li>";
          list.push(<li>{drink[thisIng]} : {drink[thisMeas]}</li>)
        }
      }
      //list += "</ul>"
      return list;
      //let arrUsers = [<li>Abdul Moiz</li>,<li>John Doe</li>]
      //return arrUsers;
    }

  return (
    <div>
      <h1>Item</h1>
      <h2>Drink: {drink.strDrink}</h2>
      <ul>{ingredients()}</ul>
      <div>
        <img src={drink.strDrinkThumb} alt="" />
      </div>
    </div>
  )
}

export default ItemDetail;
