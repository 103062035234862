import React from 'react';

import Nav from './components/Nav';
import About from './components/About';
import Shop from './components/Shop';
import ItemDetail from './components/ItemDetail';
import Footer from './components/Footer';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


function App() {
  return (
    <Router>
      <div>
        <Nav />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about/" component={About} />
          <Route path="/shop/" exact component={Shop} />
          <Route path="/shop/:id" component={ItemDetail} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

{ /* FROM https://www.youtube.com/watch?v=Law7wfdg_ls */ }
const Home = () => (
  <div>
    <h1>Home Page</h1>
  </div>
)

export default App;
