import React from 'react';

function About() {
  return (
    <div>
      About HERE
    </div>
  )
}

export default About;
