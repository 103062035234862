import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom'

function Shop() {

  const [drinks, setDrinks] = useState([]);

  useEffect(() => {
    fetchDrinks();
  },[]);

  const url = "https://the-cocktail-db.p.rapidapi.com";
  const fetchDrinks = () => {
    console.log("in fetchItems...");
    fetch("https://the-cocktail-db.p.rapidapi.com/filter.php?c=Cocktail", {
    	"method": "GET",
    	"headers": {
    		"x-rapidapi-host": "the-cocktail-db.p.rapidapi.com",
    		"x-rapidapi-key": "f113642856msh6e73ca1272580f3p12c05cjsnfbb603628da3"
    	}
    }) // this returns a promise
    .then(res => res.json()) // this returns another promise (res).
    .then(data => {
      console.log(data.drinks)
      console.log(typeof data.drinks)
      setDrinks((data.drinks))
    })
    }



  return (
    <div>
      SHOP HERE
      <br />
      {drinks.map(drink => (
        <h3 key={drink.idDrink}>
          <Link to={`/shop/${drink.idDrink}`}>{drink.strDrink}</Link>
        </h3>
      ))}

    </div>
  )
}

export default Shop;
